import { SvgIconContainer } from "./SvgIconContainer";


export const BackIcon = () => (
    <SvgIconContainer>
        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_189_2225)">
                <path d="M21.4299 14.7601C21.3799 15.0401 21.3499 15.3301 21.2899 15.6101C20.9099 17.3101 20.0099 18.6701 18.5599 19.6501C17.4399 20.4101 16.1899 20.7701 14.8399 20.7601C13.3499 20.7601 11.8599 20.7601 10.3699 20.7601C9.87992 20.7601 9.65992 20.5401 9.65992 20.0601C9.65992 19.3401 9.65992 18.6301 9.65992 17.9101C9.65992 17.4601 9.88992 17.2301 10.3399 17.2301C11.8599 17.2301 13.3799 17.2301 14.8999 17.2301C16.1599 17.2301 17.2199 16.5201 17.6799 15.3901C18.2799 13.9101 17.5899 12.2701 16.1099 11.6301C15.6899 11.4501 15.2499 11.3501 14.7899 11.3501C13.1199 11.3501 11.4599 11.3501 9.78992 11.3501C9.74992 11.3501 9.71992 11.3501 9.65992 11.3501V11.5501C9.65992 12.4301 9.65992 13.3201 9.65992 14.2001C9.65992 14.3501 9.60992 14.5301 9.51992 14.6601C9.31992 14.9401 8.96992 14.9501 8.65992 14.7101C8.06992 14.2501 7.48992 13.7801 6.90992 13.3101C5.57992 12.2501 4.25992 11.1901 2.92992 10.1301C2.49992 9.79006 2.49992 9.39006 2.92992 9.05006C4.81992 7.54006 6.69992 6.03006 8.57992 4.52006C8.75992 4.38006 8.92992 4.25006 9.17992 4.29006C9.47992 4.34006 9.66992 4.58006 9.66992 4.93006C9.66992 5.82006 9.66992 6.71006 9.66992 7.60006C9.66992 7.67006 9.66992 7.73006 9.66992 7.82006H9.89992C11.4299 7.82006 12.9599 7.82006 14.4999 7.82006C15.9899 7.82006 17.3599 8.19006 18.5899 9.03006C20.0199 10.0101 20.9499 11.3401 21.3199 13.0401C21.3799 13.3101 21.3999 13.5801 21.4399 13.8501V14.7701L21.4299 14.7601Z" fill="#2F2E2E" />
            </g>
            <defs>
                <clipPath id="clip0_189_2225">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.5)" />
                </clipPath>
            </defs>
        </svg>
    </SvgIconContainer>
);
