import { IAccessPoint } from "../../types/IAccessPoint";
import { IFacility } from "../../types/IFacility";
import { ILedger } from "../../types/ILedger";
import { ILock } from "../../types/ILock";

const currentDate = new Date();
const nextMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() - 1, 1);
const firstDayOfLastMonth = ('0' + (nextMonth.getMonth() + 1)).slice(-2) + '-' + ('0' + nextMonth.getDate()).slice(-2) + '-' + nextMonth.getFullYear();

export default {
    facility: {
        id: "2",
        name: "Facility 2",
        street: "777 Lucky Rd",
        city: "Raleigh",
        state: "NC",
        postal: "12345",
        phone: "123-456-7890",
        email: "",
        websiteUrl: "",
        tenantReviewUrl: "",
        latitude: 2,
        longitude: 2,
        companyConfigId: 1,
    },
    ledger: {
        id: "2",
        facilityId: "2",
        invoiceDayOfMonth: 1,
        rate: 64,
        active: true,
        paidThrough: firstDayOfLastMonth,
        balance: 64,
        nextChargeDate: firstDayOfLastMonth,
        nextChargeAmount: 64,
        currentPrepayBalance: 0,
        delinquent: true,
        facilityUnit: {
            unitName: "A1",
            balance: 64,
            rate: 64
        },
        tenantId: "1"
    },
    accessPoints: [{ name: "Entry Gate", id: "1" }],
    lock: {
        serialCode: "HA38P7NM",
        unitNumber: "B12",
        unlockCode: "2350",
    }
} as { facility: IFacility, ledger: ILedger, accessPoints: IAccessPoint[], lock: ILock };
