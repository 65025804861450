import React, { useState, useEffect } from 'react';
import { InputAdornment, TextField, InputLabel, styled } from '@mui/material';
import OtpInput from 'react-otp-input';
import { Colors } from '../../brand/colors';

export const Input = React.forwardRef(({ setValue, EndAdornment, ...props }: any, ref) => (
    <TextField
        inputRef={ref}
        InputProps={{
            ...(EndAdornment ? {
                endAdornment: (
                    <InputAdornment style={{ marginTop: "4px" }} position="start">
                        <EndAdornment />
                    </InputAdornment>
                )
            } : {})
        }}
        {...props} />
));

export const PhoneInput = React.forwardRef(({ ...props }: any, ref) => {
    const [value, setValue] = useState(props.defaultValue || '');

    return <Input
        {...props}
        type="tel"
        value={function () {
            if (value.length > 6) {
                return "(" + value.slice(0, 3) + ")" + " "
                    + value.slice(3, 6) + "-" + value.slice(6);
            }

            if (value.length > 3) {
                return "(" + value.slice(0, 3) + ")" + " " + value.slice(3);
            }

            return value;
        }()}
        onChange={(e: any) => {
            const val = e.target.value.replace(/[^0-9]/g, "");
            if (val.length > 10) return;

            setValue(val);
            props.setValue(val);
        }}
    />
})

export const NumberInput = React.forwardRef(({ ...props }: any, ref) => {
    // const [value, setValue] = useState(props.defaultValue || '');
    return <Input
        {...props}
        type="number"
        value={function () {
            return props.value.replace(/[^0-9]/g, "");
        }()}
        onChange={(e: any) => {
            const val = e.target.value.replace(/[^0-9]/g, "");
            if (val.length > 4) return;

            // setValue(val);
            props.setValue(val);
        }}
    />
})

// export const PasswordInput = React.forwardRef(({ ...props }, ref) => {
//     const [showPassword, setShowPassword] = useState(false);

//     return (
//         <Input
//             {...props}
//             type={showPassword ? "text" : "password"}
//             fullWidth label="Password"
//             EndAdornment={() => <ShowHideIcon onClick={() => setShowPassword(prev => !prev)} />}
//         />
//     )
// });


export const PinInput = React.forwardRef(({
    error, helperText, setValue, numInputs, pin, setPin, label, isInputNum
}: any, ref) => {
    return (
        <span id={'otp-container'}>
            <OtpInput
                value={pin}
                onChange={setPin}

                containerStyle={{
                    width: `100%`,
                    margin: "auto",
                    gap: `${60 / numInputs}px`
                }}
                shouldAutoFocus
                inputStyle={{
                    boxShadow: '0 0 0 30px white inset',
                    backgroundColor: "#fcfcfc",
                    width: `100%`,
                    borderRadius: 0,
                    padding: 0,
                    borderTop: 0,
                    borderLeft: 0,
                    borderRight: 0,
                    fontSize: "1.8rem",
                    color: Colors.black()
                }}
                numInputs={numInputs}
                renderInput={({ type, inputMode, ...props }) =>
                    <StyledInput {...props} type="tel" inputMode='numeric' />
                }
            />
            <InputLabel color="error" focused size="small" shrink sx={{ marginTop: "4px", marginLeft: "14px" }}>
                {helperText}
            </InputLabel >
        </span>
    );
});

const StyledInput = styled('input')({
    '&:-webkit-autofill, &:-webkit-autofill:hover, &:-webkit-autofill:focus, &:-webkit-autofill:active': {
        '-webkit-text-fill-color': '#000', // adjust this to your desired text color
        '-webkit-box-shadow': '0 0 0 30px white inset',
        'transition': 'background-color 5000s ease-in-out 0s' // this can help in some cases
    }
});
