import {
    Routes,
    Route
} from "react-router-dom";

import { useAnalyticsRoute } from "../../router/AppRouter";
import { auctionBuyerRoutes } from "../../router/routes";
import { AuctionBuyerMoveoutOverviewPage, AuctionBuyerPage, AuctionBuyerTakePhotoPage, ConfirmAuctionBuyerMoveoutPage } from "./pages";

export default function AuctionBuyerRoutes() {
    return (
        <Routes>
            <Route
                index
                element={useAnalyticsRoute(AuctionBuyerPage, "AuctionBuyer")} />
            <Route
                path={auctionBuyerRoutes.MoveoutOverview}
                element={useAnalyticsRoute(AuctionBuyerMoveoutOverviewPage, "AuctionBuyerMoveoutOverview")} />
            <Route
                path={auctionBuyerRoutes.MoveoutTakePhoto}
                element={useAnalyticsRoute(AuctionBuyerTakePhotoPage, "AuctionBuyerTakePhoto")} />
            <Route
                path={auctionBuyerRoutes.MoveoutConfirm}
                element={useAnalyticsRoute(ConfirmAuctionBuyerMoveoutPage, "ConfirmAuctionBuyerMoveout")} />
        </Routes>

    );
};
