import { Subtitle1 } from "../brand/typographies";
import { useContext } from "react";
import { FacilitiesContext } from "../providers/facility-provider/FacilitiesContext";

export const FacilityAddress = () => {
    const { selectedFacility } = useContext(FacilitiesContext);

    return (
        <Subtitle1
            bold
            center
            sx={{
                my: 1,
                ...(!selectedFacility?.street
                    ? { opacity: 0 }
                    : {}
                )
            }}>
            {selectedFacility?.street} {selectedFacility?.city}, {selectedFacility?.state} {selectedFacility?.postal}
        </Subtitle1>
    );
};
