import { useFetchData } from "../../../providers/account-provider/AccountProvider";
import { ILock } from "../../../types/ILock";
import agents from "../../../api/agents";
import useAnalytics from "../../../hooks/useAnalytics";
import { useEffect } from "react";
import { IFacility } from "../../../types/IFacility";

export const useUnlockCodes = (facility: IFacility | null, tenantUsingOnly: boolean = false): [ILock[], boolean] => {
    const { trackViewEvent } = useAnalytics("Viewed Unlock Code(s)", false);

    const [locks, fetchingLocks] = useFetchData<ILock[]>(
        tenantUsingOnly ? agents.Access.getTenantOwnedLocks : agents.Access.getLocks,
        "locks", facility?.id || "", true
    );

    useEffect(() => {
        if (locks.length) trackViewEvent();

        if (locks.length && locks.some(l => !l.unlockCode)) {
            trackViewEvent("Lock(s) Missing Unlock Code");
        }
    }, [locks.length]);

    return [locks, fetchingLocks];
};
