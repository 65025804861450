import { createContext, useEffect, useState } from 'react';
import { ILedger } from '../../types/ILedger';
import agents from '../../api/agents';
import { useStorSelfSearchParams } from '../../hooks/useStorSelfSearchParams';
import { useMoveoutEntryNavigation } from '../../pages/moveout/[hooks]/useMoveoutEntryNavigation';
import { useAppContext } from '../../hooks/useAppContext';
import { AccountFlowContext, IAccountFlowContext } from '../../types/AccountFlowContext';

export interface IMoveOutContext extends IAccountFlowContext {
    state: {
        blob?: Blob,
    } & AccountFlowContext,
};

export const MoveOutContext = createContext<IMoveOutContext>({
    state: {
        phoneNumber: "",
        ledgers: [],
        ledgerId: "",
        facilityId: "",
        loading: true,
        fetchingLedgers: true,
    },
    flowTitle: "Move Out",
    lookupAccount: () => { },
});

export const MoveOutContextProvider = ({ children }: any) => {
    const navigateTo = useMoveoutEntryNavigation();

    const appContext = useAppContext();

    const [
        phoneNumber,
        ledgerId,
        facilityId
    ] = useStorSelfSearchParams("phoneNumber", "ledgerId", "facilityId")

    const [fetchingLedgers, setFetchingLedgers] = useState(false)
    const [ledgers, setLedgers] = useState<ILedger[]>([])

    /** Update state methods */
    const lookupAccount = async (phoneNumber: string, replace = false, savedLedgers?: ILedger[]) => {
        setFetchingLedgers(true)

        const { data } = savedLedgers ? { data: savedLedgers } : await agents.Account.getLedgerByPhoneNumber(phoneNumber, appContext.selectedFacility!.id)

        setLedgers(data || [])
        setFetchingLedgers(false)

        if (!data.length) { // if no ledgers are returned for phone number
            navigateTo.noUnitsFound(phoneNumber, { replace });
        }
        else if (data.length === 1) { // if only one ledger is returned, skip selecting unit
            navigateTo.overview(phoneNumber, data[0].id, appContext.selectedFacility!, { replace })
        }
        else { // if multiple ledgers, go select a unit
            navigateTo.selectUnit(phoneNumber, appContext.selectedFacility!, { replace })
        }
    }

    /** load ledgers from logged in account from app context */
    useEffect(() => {
        if (!appContext.loadingAppContext && appContext.phoneNumber) {
            setLedgers(appContext.facilityLedgers)
            setFetchingLedgers(false)
        }
    }, [appContext.loadingAppContext])

    /** Look up account if there is a phone number param... on mount... */
    useEffect(() => {
        if (phoneNumber && !appContext.phoneNumber) {
            lookupAccount(phoneNumber)
        }
    }, [])

    return (
        <MoveOutContext.Provider value={{
            state: {
                phoneNumber,
                ledgers,
                ledgerId,
                facilityId,
                fetchingLedgers,
                loading: appContext.loadingAppContext || fetchingLedgers || appContext.loadingAppContext
            },
            flowTitle: "Move Out",
            lookupAccount,
        }}>
            {children}
        </MoveOutContext.Provider>
    );
};
