import { FacilityAddress } from "../../components/FacilityAddress";
import { FlexBox } from "../../components/layout/FlexBox";
import { PageTitle } from "../../brand/typographies";
import { RentSpaceButton } from "../../components/buttons/NavigationButtons/RentSpaceButton";
import { ContactUsButton } from "../../components/buttons/NavigationButtons/ContactUsButton";
import { MoveOutButton } from "../../components/buttons/NavigationButtons/MoveOutButton";
import { AccessButton } from "../../components/buttons/NavigationButtons/AccessButton";
import { PaymentsButton } from "../../components/buttons/NavigationButtons/PaymentsButton";

export const ExistingCustomerPage = () => {
    return (
        <>
            <PageTitle>Existing Customers</PageTitle>

            <FacilityAddress />

            <FlexBox sx={{ my: 4 }}>
                <PaymentsButton />

                <AccessButton />

                <RentSpaceButton />

                <ContactUsButton
                    fullWidth={!_context.companySettings.isMoveOutEnabled}
                    existingCustomer
                />
                {_context.companySettings.isMoveOutEnabled
                    && <MoveOutButton />
                }
            </FlexBox>
        </>
    );
};
