import {
    createContext,
    useContext,
    useEffect,
    useMemo,
    useState
} from "react";
import { AuthContext } from "../auth-provider/AuthProvider";
import agents from "../../api/agents";
import { ILedger } from "../../types/ILedger";
import equal from 'deep-equal';
import { useFacilityIdParam } from "../facility-provider/[hooks]/useFacilityIdParam";

interface IAccountContext {
    ledgers: ILedger[],
    fetchingLedgers: boolean,
    refetchLedgers: () => Promise<void>,
    delinquentAccount: boolean,
    fetchingAccesspoints: boolean,
    accessPoints: { name: string, id: string }[],
};

export const AccountContext = createContext<IAccountContext>({
    ledgers: [],
    fetchingLedgers: false,
    refetchLedgers: async () => { },
    delinquentAccount: false,
    fetchingAccesspoints: false,
    accessPoints: [],
});

export function useFetchData<T extends Array<any>>(
    fetchMethod: (facilityId?: any) => Promise<{ data?: T }>,
    key: string,
    facilityId: string,
    authenticated: boolean
): [T, boolean, () => Promise<void>] {
    const [state, setState] = useState<T>(
        authenticated
            ? JSON.parse(localStorage.getItem(key) || "[]") // try to pull cached data while we are waiting on fetch
            : [] // don't pull data out of local storage if the tenant is no longer authorized with a saved phone number
    );

    const [fetchingData, setFetchingData] = useState(
        // if we're authenticated, we know for a fact that we will be fetching the data
        authenticated
    );

    const fetchData = async () => {
        try {
            setFetchingData(true)
            const response = await fetchMethod(facilityId)

            if (response.data) {
                if (!equal(state, response.data)) { // if stored data hasn't changed, don't update state
                    setState(response.data || []);
                    localStorage.setItem(key, JSON.stringify(response.data || []))
                }
            }
        } finally {
            setFetchingData(false)
        }
    }

    useEffect(() => {
        if (authenticated && facilityId) {
            fetchData()
        }
    }, [authenticated, facilityId])

    return [state, fetchingData, fetchData]
}

const AccountProvider = ({ children }: { children: any }) => {
    const { phoneNumber } = useContext(AuthContext)
    const [facilityIDParam] = useFacilityIdParam();

    const [ledgers, fetchingLedgers, refetchLedgers] = useFetchData<ILedger[]>(
        agents.Account.getAccount,
        "ledgers", "all" || "", !!phoneNumber
    )

    const delinquentAccount = useMemo(() => ledgers.some(l => l.delinquent), [ledgers])

    const [accessPoints, fetchingAccesspoints] = useFetchData<{ name: string; id: string; }[]>(
        agents.Access.getAccessPoints,
        "accessPoints", facilityIDParam || "", !!phoneNumber
    )

    return (
        <AccountContext.Provider
            value={{
                ledgers,
                fetchingLedgers,
                refetchLedgers,
                delinquentAccount,
                fetchingAccesspoints,
                accessPoints,
            }}
        >
            {children}
        </AccountContext.Provider>

    )
}

export default AccountProvider;
