import { Skeleton, SxProps, Theme, Typography } from "@mui/material";
import { Colors } from "./colors";
import React from "react";
import { Variant } from "@mui/material/styles/createTypography";

interface ITypography {
    onClick?: () => void,
    center?: boolean,
    white?: boolean,
    black?: boolean,
    brandColor?: boolean,
    children: React.ReactNode,
    bold?: boolean,
    underlined?: boolean,
    loading?: boolean,
    sx?: SxProps<Theme>,
    error?: boolean,
    success?: boolean,
}

export const H1 = ({
    children,
    ...props
}: ITypography) => {
    return (
        <BaseTypography
            variant="h1"
            {...props}>
            {children}
        </BaseTypography>
    )
}

export const H2 = ({
    children,
    ...props
}: ITypography) => {
    return (
        <BaseTypography
            variant="h2"
            {...props}>
            {children}
        </BaseTypography>
    )
}

export const H3 = ({
    children,
    ...props
}: ITypography) => {
    return (
        <BaseTypography
            variant="h3"
            {...props}>
            {children}
        </BaseTypography>
    )
}

export const H4 = ({
    children,
    ...props
}: ITypography) => {
    return (
        <BaseTypography
            variant="h4"
            {...props}>
            {children}
        </BaseTypography>
    )
}

export const H5 = ({
    children,
    ...props
}: ITypography) => {
    return (
        <BaseTypography
            variant="h5"
            {...props}>
            {children}
        </BaseTypography>
    )
}

export const H6 = ({
    children,
    ...props
}: ITypography) => {
    return (
        <BaseTypography
            variant="h6"
            {...props}>
            {children}
        </BaseTypography>
    )
}

export const Subtitle1 = ({
    children,
    ...props
}: ITypography) => {
    return (
        <BaseTypography
            variant="subtitle1"
            {...props}>
            {children}
        </BaseTypography>
    )
}

export const Subtitle2 = ({
    children,
    ...props
}: ITypography) => {
    return (
        <BaseTypography
            variant="subtitle2"
            {...props}>
            {children}
        </BaseTypography>
    )
}

export const Body1 = ({
    children,
    ...props
}: ITypography) => {
    return (
        <BaseTypography
            variant="body1"
            {...props}>
            {children}
        </BaseTypography>
    )
}

export const Body2 = ({
    children,
    ...props
}: ITypography) => {
    return (
        <BaseTypography
            variant="body2"
            {...props}>
            {children}
        </BaseTypography>
    )
}

export const Caption = ({
    children,
    ...props
}: ITypography) => {
    return (
        <BaseTypography
            variant="caption"
            component="p"
            {...props}>
            {children}
        </BaseTypography>
    )
}

interface IBaseTypography extends ITypography {
    variant: Variant,
    component?: string
}

const BaseTypography = ({
    children,
    center,
    white,
    brandColor,
    bold,
    underlined,
    sx = {},
    loading,
    error,
    success,
    ...props
}: IBaseTypography) => {
    if (loading) {
        return <Skeleton height={heightLookup[props.variant] || 24} />
    }

    return (
        <Typography
            {...(center ? { align: "center", margin: "auto" } : {})}
            {...(white ? { color: "#FCFCFC" } : {})}
            {...(brandColor ? { color: Colors.primary() } : {})}
            {...(bold ? { fontWeight: 700 } : {})}
            {...(error ? { color: "error" } : {})}
            {...(success ? { color: "#54B239" } : {})}
            sx={{
                ...sx,
                ...(underlined ? { textDecoration: "underline" } : {})
            }}
            {...props}>
            {children}
        </Typography>
    )
}

export const PageTitle = ({ children, white, sx = {} }: { children: React.ReactNode, white?: boolean, sx?: SxProps<Theme> }) => (
    <H3 sx={sx} bold center white={white}>{children}</H3>
)

const heightLookup = {
    "h1": 69,
    "h2": 40,
    "h3": 33,
    "h4": 28,
    "h5": 25,
    "h6": 22,
    "subtitle1": 21,
    "subtitle2": 20,
    "body1": 12,
    "body2": 16,
    "caption": 13,
    "button": 25,
    "overline": 25
}
