import { Caption, Subtitle1 } from '../../../brand/typographies';
import { IFacility } from '../../../types/IFacility';


export const CardFacilityAddress = ({ facility }: { facility?: IFacility | null; }) => (
    <>
        <Subtitle1 loading={!facility} center bold brandColor>
            {facility?.street}
        </Subtitle1>
        <Caption loading={!facility} center>
            {facility?.city}, {facility?.state} {facility?.postal}
        </Caption>
    </>
);
