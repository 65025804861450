import styled from 'styled-components';
import { AspectRatio } from './types';

export const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
`;

export const Container = styled.div<{ aspectRatio: AspectRatio }>({
  position: 'fixed',
  width: '100vw',
  height: '100vh',
  top: 0,
  left: 0,
  baxckgroundColor: 'orange',
});


export const ErrorMsg = styled.div`
  padding: 40px;
`;

export const Cam = styled.video<{ mirrored: boolean }>({
  width: "100vw",
  height: "100vh",
  objectFit: "cover",
  // position: "fixed",
  left: 0,
  top: 0,
  zIndex: 1,
  transform: "translate3d(0, 0, 1px) rotateY(${({ mirrored }) => (mirrored ? '180deg' : '0deg')})",
})

export const Canvas = styled.canvas`
  display: none;
`;
