import { ILedger } from '../types/ILedger';
import { ColumnedRow } from './ColumnedRow';

export const LedgerRow = ({ ledger, loading }: { loading: boolean, ledger: ILedger }) => {
    return <ColumnedRow
        loading={loading}
        data={[
            { label: "Unit", value: ledger?.facilityUnit?.unitName },
            { label: "Paid Through", value: ledger?.paidThrough },
            { label: "Balance", value: "$" + ledger?.balance?.toFixed(2) }
        ]}
    />
};
