import React, { createContext, useContext, useEffect } from "react";
import { analyticsService } from "../services/analytics-service";
import { AuthContext } from "./auth-provider/AuthProvider";

interface IAnalytics {
    pageName: string,
}
export const AnalyticsContext = createContext<IAnalytics>({
    pageName: ""
})

const AnalyticsProvider = ({ children, pageName, }:
    {
        children: any,
        pageName: string,
    }) => {
    const { phoneNumber } = useContext(AuthContext)

    useEffect(() => {
        analyticsService.trackViewEvent(pageName, pageName, { phoneNumber });
    }, [phoneNumber, pageName]);

    console.log("AnalyticsProvider")

    return (
        <AnalyticsContext.Provider value={{
            pageName
        }}>
            {children}
        </AnalyticsContext.Provider>
    )
}

export default AnalyticsProvider;
