import { IFacility } from "../types/IFacility";
import { Card } from "@mui/material";
import { CardFacilityAddress } from "../pages/account/[components]/CardFacilityAddress";
import React from "react";


export const FacilityCard = ({ facility, children }: { facility?: IFacility | null; children: React.ReactNode; }) => {
    return (
        <Card
            variant="outlined"
            sx={{
                borderColor: "#2F2E2E",
                borderRadius: "12px",
                p: "12px",
                boxSizing: "border-box",
                mb: 4,
                mt: 2,
            }}>

            <CardFacilityAddress facility={facility} />

            {children}
        </Card>
    );
};
