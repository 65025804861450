import { styled } from "@mui/material";

// TODO: replace with either Section or TwoColumn from /layout directory
export const FlexBox = styled("div")({
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    justifyContent: "center",
    alignItems: "center",
    width: "100%",
    gap: "16px"
});
