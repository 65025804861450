import { LockIcon } from "../../icons";
import { StorSelfIconButton } from "../StorSelfIconButton";

/**
 * Renders a button that navigates to the page for opening gates and doors and unlocking units.
 */
export const AccessButton = () => (
    <StorSelfIconButton
        Icon={LockIcon}
        to="/access"
        text="Access" />
);
