import { createContext, useEffect, useMemo, useState } from 'react';
import { ILedger } from '../../types/ILedger';
import agents from '../../api/agents';
import { useAppContext } from '../../hooks/useAppContext';
import { AccountFlowContext, IAccountFlowContext } from '../../types/AccountFlowContext';
import { usePaymentNavigation } from '../../pages/payments/[hooks]/usePaymentNavigation';
import { NumberParam, StringParam, UrlUpdateType, useQueryParam } from 'use-query-params';
import { IPaymentMethod } from '../../types/PaymentMethod';
import equal from 'deep-equal';
import { useFacilityIdParam } from '../facility-provider/[hooks]/useFacilityIdParam';
import { ExternalPaymentForm } from '../../pages/payments';
import { PaymentSecurityLevel } from '../../types/PaymentSecurityLevel';
interface IPaymentsContext extends IAccountFlowContext {
    state: {
        phoneNumber: string;
        ledgers: ILedger[];
        ledger: ILedger | null;
        ledgerId: string;
        facilityId: string;
        loading: boolean;
        prePayMonths: number;
        paymentAmount: number,
        fetchingPaymentAmount: boolean;
        ledgerEligible: LedgerEligible;
        fetchingPaymentMethods: boolean,
        paymentMethods: IPaymentMethod[]
    } & AccountFlowContext,
    setPrePayMonths: (months: number, updateType?: UrlUpdateType | undefined) => void
};

export enum LedgerEligible {
    Loading,
    Eligible,
    Ineligible,
}

export const PaymentsContext = createContext<IPaymentsContext>({
    state: {
        phoneNumber: "",
        ledgers: [],
        ledger: null,
        ledgerId: "",
        facilityId: "",
        loading: true,
        prePayMonths: 0,
        paymentAmount: 0,
        fetchingPaymentAmount: false,
        ledgerEligible: LedgerEligible.Loading,
        fetchingPaymentMethods: false,
        paymentMethods: []
    },
    flowTitle: "Make a Payment",
    lookupAccount: () => { },
    setPrePayMonths: (months: number, updateType?: UrlUpdateType | undefined) => { }
});

export const PaymentsContextProvider = ({ children }: any) => {
    const appContext = useAppContext();

    const navigateTo = usePaymentNavigation()

    const [phoneNumber, setPhoneNumber] = useQueryParam("phoneNumber", StringParam)
    const [ledgerId] = useQueryParam("ledgerId", StringParam)
    const [facilityId] = useFacilityIdParam()
    const [prePayMonths, setPrePayMonths] = useQueryParam("prePayMonths", NumberParam)

    const [ledgerEligible, setLedgerEligible] = useState(LedgerEligible.Loading)

    const [fetchingLedgers, setFetchingLedgers] = useState(false)
    const [ledgers, setLedgers] = useState<ILedger[]>([])
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [fetchingPaymentAmount, setFetchingPaymentAmount] = useState(!!prePayMonths)

    const ledger = useMemo(() => ledgers.find(l => l.id == ledgerId) || null, [ledgers, ledgerId])

    useEffect(() => {
        /**
         * The query param phone number might not be the same as the phone number
         * attached to the tenant auth. If the payment security level only allows
         * for authorized users to only be able to pay for their own account
         * THEN make overwrite any differing number with their own number.
         */
        if (
            _context.companySettings.paymentSecurityLevel == PaymentSecurityLevel.AuthorizedAccountOnly
            && phoneNumber
            && phoneNumber != appContext.phoneNumber) {
            setPhoneNumber(appContext.phoneNumber)
        }
    }, [phoneNumber, appContext.phoneNumber])

    useEffect(() => {
        const getLedgerEligibility = async () => {
            if (facilityId && ledgerId) {
                try {

                    const result = await agents.Payments.getLedgerEligibility(facilityId, ledgerId)

                    if (result.data?.eligibleForPayment) {
                        setLedgerEligible(LedgerEligible.Eligible)
                    } else {
                        setLedgerEligible(LedgerEligible.Ineligible)
                    }
                } catch (err) {
                    console.log({ err })
                }
            }
        };

        if (ledgerId && facilityId) {
            getLedgerEligibility()
        }
    }, [ledgerId, facilityId])

    useEffect(() => {
        const getLedgerPrepayAmount = async () => {
            try {
                if (facilityId && ledgerId && prePayMonths) {
                    setFetchingPaymentAmount(true)
                    const result = await agents.Payments.getLedgerPrepayAmount(facilityId, ledgerId, prePayMonths)
                    setFetchingPaymentAmount(false)
                    setPaymentAmount(result.data.amount || ledger?.balance || 0)
                }
            } catch (err) {
                console.log({ err })
            }
        };

        if (ledgerId && facilityId && prePayMonths) {
            getLedgerPrepayAmount()
        } else {
            setPaymentAmount(ledger?.balance || 0)
        }
    }, [ledgerId, facilityId, prePayMonths, ledger])


    // default to true so that if the initial page is the
    // select payment method. Then wait to fetch methods before
    // navigating to entering new card if no method is found...
    // consider moving this out of context...
    const [fetchingPaymentMethods, setFetchingPaymentMethods] = useState(true)
    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([])

    useEffect(() => {
        const fetchPaymentMethods = async (tenantId: string, ledgerId: string, facilityId: string) => {
            setFetchingPaymentMethods(true)
            const result = await agents.Payments.getPaymentMethods(facilityId, tenantId, ledgerId);

            if (!result?.data?.length) {
                setPaymentMethods([])
                setFetchingPaymentMethods(false)
                return;
            }

            setFetchingPaymentMethods(false)
            setPaymentMethods(
                // filter out duplicates
                result.data.reduce<IPaymentMethod[]>((acc, current) => {
                    const { id, ...withOutId } = current
                    if (!acc.some(({ id, ...item }) => equal(item, withOutId))) {
                        acc.push(current);
                    }
                    return acc;
                }, [])
            )
        }

        if (ledger?.tenantId && facilityId) {
            fetchPaymentMethods(ledger.tenantId, ledger.id, facilityId)
        } else {
            // setFetchingPaymentMethods(false)
            setPaymentMethods([])
        }
    }, [ledger?.tenantId, facilityId])

    /** Update state methods */
    const lookupAccount = async (phoneNumber: string, replace = false, savedLedgers?: ILedger[]) => {
        if (!facilityId || !appContext.selectedFacility?.id) {
            setLedgers([])
            setFetchingLedgers(false)

            navigateTo.noUnitsFound({ phoneNumber }, { replace });
        }

        setFetchingLedgers(true)

        const { data } = savedLedgers ? { data: savedLedgers } : await agents.Account.getLedgerByPhoneNumber(phoneNumber, appContext.selectedFacility?.id || facilityId || "")

        setLedgers(data || [])
        setFetchingLedgers(false)

        if (!data.length) { // if no ledgers are returned for phone number
            navigateTo.noUnitsFound({ phoneNumber }, { replace });
        }
        else if (data.length === 1) { // if only one ledger is returned, skip selecting unit
            navigateTo.paymentAmount({ phoneNumber, ledgerId: data[0].id }, { replace })
        }
        else { // if multiple ledgers, go select a unit
            navigateTo.selectUnit({ phoneNumber }, { replace })
        }
    }

    /** load ledgers from logged in account from app context */
    useEffect(() => {
        if (!appContext.loadingAppContext && appContext.phoneNumber) {
            setLedgers(appContext.facilityLedgers)
        }
    }, [appContext.loadingAppContext])

    /** Look up account if there is a phone number param... on mount... */
    useEffect(() => {
        if (phoneNumber && !appContext.phoneNumber) {
            lookupAccount(phoneNumber)
        }
    }, [])

    console.log({ ledger })

    if (ledger?.paymentUrl) {
        return <PaymentsContext.Provider value={{
            state: {
                phoneNumber: phoneNumber || "",
                ledgers,
                ledgerId: ledgerId || "",
                facilityId: facilityId || "",
                loading: appContext.loadingAppContext || fetchingLedgers,
                prePayMonths: prePayMonths || 0,
                paymentAmount: paymentAmount || ledger?.balance || 0,
                fetchingPaymentAmount,
                ledgerEligible,
                fetchingPaymentMethods,
                paymentMethods,
                ledger,
            },
            flowTitle: "Make a Payment",
            lookupAccount,
            setPrePayMonths,
        }}>
            <ExternalPaymentForm />
        </PaymentsContext.Provider>
    }

    return (
        <PaymentsContext.Provider value={{
            state: {
                phoneNumber: phoneNumber || "",
                ledgers,
                ledgerId: ledgerId || "",
                facilityId: facilityId || "",
                loading: appContext.loadingAppContext || fetchingLedgers,
                prePayMonths: prePayMonths || 0,
                paymentAmount: paymentAmount || ledger?.balance || 0,
                fetchingPaymentAmount,
                ledgerEligible,
                fetchingPaymentMethods,
                paymentMethods,
                ledger,
            },
            flowTitle: "Make a Payment",
            lookupAccount,
            setPrePayMonths,
        }}>
            {children}
        </PaymentsContext.Provider>
    );
};
