import { IFacility } from "../types/IFacility";
import { ILedger } from "../types/ILedger";
import { Divider } from "@mui/material";
import { LedgerRow } from "./LedgerRow";
import { Colors } from "../brand/colors";
import { FacilityCard } from "./FacilityCard";
import { StorSelfSmallButton } from "./buttons/StorSelfSmallButton";

export const UnitsCard = ({
    facility,
    ledgers,
    loading,
    callbackPage,
    phoneNumber
}: {
    callbackPage?: string;
    facility?: IFacility | null;
    ledgers: ILedger[];
    loading?: boolean;
    phoneNumber: string
}) => {
    return (
        <FacilityCard facility={facility}>
            {ledgers.map((ledger, index) => (
                <span key={`leder-row-${ledger.id}`}>
                    <LedgerRow
                        ledger={ledger}
                        loading={!!loading}
                    />

                    {facility && !loading && ledger.delinquent && callbackPage &&
                        // TODO: implement callbackPage logic
                        <StorSelfSmallButton
                            to={`/payments/payment-amount?ledgerId=${ledger.id}&facilityId=${facility!.id}&phoneNumber=${phoneNumber}&callback=` + callbackPage}
                        >
                            Pay
                        </StorSelfSmallButton>
                    }

                    {index < ledgers.length - 1 && <Divider sx={{ my: 2, borderColor: Colors.primary() }} />}
                </span>
            ))}
        </FacilityCard>);
};
