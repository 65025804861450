import { createTheme, getContrastRatio } from "@mui/material";
import { companyConfig } from "../globalContext";
import { Colors } from "./colors";

export const theme = createTheme({
    palette: {
        primary: {
            main: companyConfig.primaryColor,
        },
    },
    typography: {
        fontFamily: 'Inter, Montserrat, Arial, sans-serif',
        h1: {
            fontSize: '60px',
            fontWeight: 400,
            lineHeight: '70px',
            letterSpacing: '0.15px',
            color: '#191919',
        },
        h2: {
            fontSize: '34px',
            fontWeight: 400,
            lineHeight: '41px',
            letterSpacing: '0.126px',
            color: '#191919',
        },
        h3: {
            fontSize: '28px',
            fontWeight: 400,
            lineHeight: '34px',
            letterSpacing: '0.101px',
            color: '#191919',
        },
        h4: {
            fontSize: '22px',
            fontWeight: 400,
            lineHeight: '28px',
            letterSpacing: '0.077px',
            color: '#191919',
        },
        h5: {
            fontSize: '20px',
            fontWeight: 400,
            lineHeight: '25px',
            letterSpacing: '0.076px',
            color: '#191919',
        },
        h6: {
            fontSize: '17px',
            fontWeight: 400,
            lineHeight: '22px',
            letterSpacing: '-0.07px',
            color: '#191919',
        },
        subtitle1: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '21px',
            letterSpacing: '-0.051px',
            color: '#191919', // or typographySecondary
        },
        subtitle2: {
            fontSize: '15px',
            fontWeight: 400,
            lineHeight: '20px',
            letterSpacing: '-0.036px',
            color: '#191919', // or typographySecondary
        },
        body1: {
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '12px',
            color: '#191919', // or typographySecondary
        },
        body2: {
            fontSize: '12px',
            fontWeight: 400,
            lineHeight: '16px',
            color: '#191919', // or typographySecondary
        },
        caption: {
            fontSize: '11px',
            fontWeight: 400,
            lineHeight: '13px',
            color: '#191919', // or typographySecondary
        },
        button: {
            fontSize: '17px',
            fontWeight: 700,
            lineHeight: '28px',
            letterSpacing: '-0.07px',
            color: '#191919', // or typographySecondary
        },
    },
    components: {
        MuiBottomNavigation: {
            styleOverrides: {
                root: {
                    ".MuiBottomNavigationAction-label": {
                        fontSize: "0.7rem",
                        color: "rgb(47, 46, 46)",
                    }
                }
            }
        },
        MuiButton: {
            styleOverrides: {
                root: {
                    borderRadius: "8px",
                    padding: "17px 0",
                    height: "56px",
                    textTransform: "none",
                    width: "100%",
                    '&.Mui-disabled': {
                        backgroundColor: '#C7C7CC',
                        color: '#fff', // You might want to change the text color for disabled buttons too
                    },
                    '&:hover, &:focus': {
                        // color: 'inherit',
                    },
                },
                outlined: {
                    border: '1.5px solid',
                    '&:hover, &:focus': {
                        color: Colors.primary(),
                    },
                },
            }
        },
        MuiBottomNavigationAction: {
            styleOverrides: {
                root: {
                    padding: 0,
                    "svg": {
                        height: "25px!important"
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    // fontSize: "12px"
                }
            }
        }
    }
});
