import axios, { AxiosResponse } from 'axios';
import { companyConfig } from '../../globalContext';
import { ILedger } from '../../types/ILedger';
import { IPaymentMethod } from '../../types/PaymentMethod';
import { IFacility } from '../../types/IFacility';
import delinquentAccount from "./delinquent";
import newMoveIn from "./newMoveIn";
import { IAccessPoint } from '../../types/IAccessPoint';
import delinquent from './delinquent';
import { ILock } from '../../types/ILock';

const delay = (ms: number): Promise<any> => {
    return new Promise((resolve: TimerHandler) => setTimeout(resolve, ms));
}

axios.defaults.headers.common["Access-Control-Allow-Origin"] = "*"

axios.defaults.withCredentials = true;

const responseBody = <T>(response: AxiosResponse<T>) => {
    return response.data
};

export const storageKeys = {
    phoneToken: "phoneToken"
}

let accessToken = localStorage.getItem(storageKeys.phoneToken) || ""
export const getToken = () => accessToken || localStorage.getItem(storageKeys.phoneToken) || ""
export const setLocalStorageToken = (token: string) => {
    accessToken = token
}

const asyncFetch = async<T>(result: T, ms: number = 1000, success: boolean = true): Promise<{ data: T, success: boolean }> => {
    await delay(ms);

    return { data: result, success };
}

const requests = {
    get: <T>(url: string) => {
        return axios.get<T>(url, {
            headers: {
                "PhoneToken": getToken(),
            }
        }).then(responseBody)
    },
    post: <T>(url: string, body: {}) => axios.post<T>(url, body, {
        headers: {
            "PhoneToken": getToken(),
        }
    }).then(responseBody),
    postImage: <T>(url: string, body: {}) => axios.post<T>(url, body, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(responseBody),
    put: <T>(url: string, body: {}) => axios.put<T>(url, body, {
        headers: {
            "PhoneToken": getToken(),
        }
    }).then(responseBody),
    delete: <T>(url: string) => axios.delete<T>(url, {
        headers: {
            "PhoneToken": getToken(),
        }
    }).then(responseBody),
}

const Facility = {
    getFacilities: () =>
        asyncFetch<IFacility[]>([newMoveIn.facility, delinquentAccount.facility], 400),
    getLedgerByPhoneNumber: (phoneNumber: string, facilityID: string) =>
        asyncFetch<any>({}, 1200),
    initiateMoveOut: (imageData: FormData) => requests.postImage<any>("/api/demo/initiate-move-out", imageData),
    initiateRemoteMoveOut: (imageData: any) => requests.post<any>("/api/demo/initiate-remote-move-out", imageData),
    initiateAuctionMoveOut: (imageData: FormData) => requests.postImage<any>("/api/demo/initiate-auction-move-out", imageData),
}

const Account = {
    getAccount: () => asyncFetch<ILedger[]>([newMoveIn.ledger, delinquentAccount.ledger], 400),
    getLedgerByPhoneNumber: (phoneNumber: string, facilityID: string) =>
        asyncFetch<ILedger[]>([facilityID === "1" ? newMoveIn.ledger : delinquentAccount.ledger], 1200),
}

const Access = {
    getAccessPoints: (facilityID: string) =>
        asyncFetch<IAccessPoint[]>(facilityID === "1" ? newMoveIn.accessPoints : delinquent.accessPoints, 1200),
    openAccessPoint: (facilityID: string, accessID: string) => {
        if (facilityID === "1") {
            return asyncFetch<any>({}, 1200)
        } else {
            if (delinquent.ledger.delinquent) {
                return asyncFetch<any>({}, 1200, false)
            }
            return asyncFetch<any>({}, 1200)
        }
    },
    getLocks: (facilityID: string) => {
        if (facilityID === "1") {
            return asyncFetch<ILock[]>([newMoveIn.lock], 1200)
        } else {
            if (delinquent.ledger.delinquent) {
                return asyncFetch<ILock[]>([], 1200, false)
            }
            return asyncFetch<ILock[]>([delinquent.lock], 1200, false)
        }
    },
    getTenantOwnedLocks: (facilityID: string) => {
        if (facilityID === "1") {
            return asyncFetch<ILock[]>([newMoveIn.lock], 1200)
        } else {
            if (delinquent.ledger.delinquent) {
                return asyncFetch<ILock[]>([], 1200, false)
            }
            return asyncFetch<ILock[]>([], 1200, false)
        }
    },
}

const OTP = {
    sendOTP: (phoneNumber: string) => axios.post<string>("/api/demo/otp/send?phoneNumber=" + phoneNumber, {}, { withCredentials: true }).then(responseBody),
    resendOTP: () => axios.post<string>("/api/demo/otp/resend", {}, { withCredentials: true }).then(responseBody),
    verifyOTP: (otp: string) => axios.post<string>(`/api/demo/otp/verify?otp=${otp}`, {}, { withCredentials: true }).then(responseBody),
}

const Payments = {
    getLedgerEligibility: (_: string, __: string):
        Promise<{
            data: {
                eligibleForPayment: boolean
            }
        }> => asyncFetch<{ eligibleForPayment: boolean }>({ eligibleForPayment: true }, 300),
    getLedgerPrepayAmount: (facilityId: string, _: string, prepayMonths: number) =>
        asyncFetch<{ amount: number }>({
            amount: facilityId === "1" ? newMoveIn.ledger.rate * (prepayMonths + 1) : delinquent.ledger.rate * (prepayMonths + 1)
        }, 300),
    getPaymentMethods: (_: string, __: string) =>
        asyncFetch<IPaymentMethod[]>([{
            id: "1",
            kind: "credit_card",
            firstName: "John",
            lastName: "Doe",
            fullName: "John Doe",
            cardNumber: "XXXXXXXXXXXX3456",
            cardType: "visa",
            expirationDate: "12/24",
            expirationMonth: "12",
            expirationYear: "24",
            paymentMethodId: "1"
        },
        {
            id: "2",
            kind: "credit_card",
            firstName: "John",
            lastName: "Doe",
            fullName: "John Doe",
            cardNumber: "XXXXXXXXXXXX1280",
            cardType: "visa",
            expirationDate: "05/28",
            expirationMonth: "05",
            expirationYear: "28",
            paymentMethodId: "2"
        }], 300),
    getAllowedPrePayMonths: () =>
        asyncFetch<number>(6, 300)
}

const Logger = {
    logError: (data: { message: string, type?: number }) => asyncFetch<number>(6, 300),
}

const agents = {
    Account,
    Access,
    Facility,
    Payments,
    OTP,
    Logger
}

export default agents
