import { Button } from "@mui/material";
import { useStoreSelfNavigation } from "../../hooks/useStorSelfNavigation";
import useAnalytics from "../../hooks/useAnalytics";
import { PrimaryButtonProps } from "./StorSelfButton";


export const StorSelfSmallButton = ({ analyticsTag, to, ...props }: PrimaryButtonProps) => {
    const { trackClickEvent } = useAnalytics();
    const navigate = useStoreSelfNavigation();

    const onClick = (e: any) => {
        trackClickEvent(analyticsTag || "Navigate - " + to);
        if (to) {
            return navigate(to);
        } else {
            props.onClick && props.onClick(e);
        }
    };

    return (
        <Button
            onClick={onClick}
            variant="contained" sx={{ mt: 1, height: "40px" }}>Pay</Button>
    );
};
