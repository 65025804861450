import {
    useCallback,
    useEffect, useState
} from "react";
import equal from 'deep-equal';

import { createFetchOnce } from "../../../utils/createFetchOnce";
import { IFacility } from "../../../types/IFacility";
import agents from "../../../api/agents";

export const useFetchFacilities = (): [IFacility[], boolean] => {
    const [facilities, setFacilities] = useState<IFacility[]>(JSON.parse(localStorage.getItem("facilities") || "[]"));
    const [fetchingFacilities, setFetchingFacilities] = useState(!facilities.length);

    const fetchFacilities = useCallback(createFetchOnce(async () => {
        const result = await agents.Facility.getFacilities();

        if (!equal(facilities, result.data)) { // if stored facilities hasn't changed, don't update state
            setFacilities(result.data || []);
        }
        localStorage.setItem("facilities", JSON.stringify(result.data || []));
        setFetchingFacilities(false);
    }), []);

    useEffect(() => {
        fetchFacilities();
    }, []);

    return [facilities, fetchingFacilities];
};
