function LogoutIcon() {
    return (
        <svg width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_13378_1581" maskUnits="userSpaceOnUse" x="0" y="0" width="21" height="21">
                <rect x="0.5" y="0.369141" width="20" height="20" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_13378_1581)">
                <path d="M5 17.3691C4.5875 17.3691 4.23437 17.2223 3.94062 16.9285C3.64687 16.6348 3.5 16.2816 3.5 15.8691V4.86914C3.5 4.45664 3.64687 4.10352 3.94062 3.80977C4.23437 3.51602 4.5875 3.36914 5 3.36914H10.5V4.86914H5V15.8691H10.5V17.3691H5ZM14 13.8691L12.9375 12.8066L14.625 11.1191H8.5V9.61914H14.625L12.9375 7.93164L14 6.86914L17.5 10.3691L14 13.8691Z" fill="#002962" />
            </g>
        </svg>
    );
}

export default LogoutIcon;
