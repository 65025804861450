/// Script is from:
/// https://www.movable-type.co.uk/scripts/latlong.html
export const calcLatLongDistance = (lat1: number, long1: number, lat2: number, long2: number) => {
    const R = 6371e3; // metres
    const φ1 = lat1 * Math.PI / 180; // φ, λ in radians
    const φ2 = lat2 * Math.PI / 180;
    const Δφ = (lat2 - lat1) * Math.PI / 180;
    const Δλ = (long2 - long1) * Math.PI / 180;

    const a = Math.sin(Δφ / 2) * Math.sin(Δφ / 2) +
        Math.cos(φ1) * Math.cos(φ2) *
        Math.sin(Δλ / 2) * Math.sin(Δλ / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));

    return R * c; // in metres
}

export const isAtFacility = (userLat: number, userLong: number, facilityLat: number, facilityLong: number, threshold: number) => {
    const distanceInMeters = calcLatLongDistance(userLat, userLong, facilityLat, facilityLong);

    return distanceInMeters <= threshold; // Returns true if within the facility boundary
}
