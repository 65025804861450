import { FacilitySelect } from "../pages";
import { useContext } from "react";
import { PopoverContext } from "../providers/PopoverContextProvider";
import { useLocation } from "react-router-dom";
import { FacilitiesContext } from "../providers/facility-provider/FacilitiesContext";

// TODO: fix- this hook is an anti pattern.
export const useSelectFacility = (showHeader = true, props: any = {}) => {
    const { onShowPopover, onClosePopover } = useContext(PopoverContext);
    const { pathname } = useLocation();
    const isLandingPage = pathname === "/";
    const { facilities, setSelectedFacility, closestFacility } = useContext(FacilitiesContext);

    const showSelectFacility = () => {
        onShowPopover(
            <FacilitySelect
                onContinue={onClosePopover}
                facilities={facilities}
                closestFacility={closestFacility}
                setSelectedFacility={setSelectedFacility}
                {...props} />,
            {
                showHeader: true, //!isLandingPage && showHeader,
                light: true,
            }
        )
    }

    return { showSelectFacility }
};
