import { NavigateOptions, useNavigate, useSearchParams } from 'react-router-dom';
import { kioskFacilityId } from '../globalContext';

export const useStoreSelfNavigation = () => {
    const navigate = useNavigate();
    const [searchParams] = useSearchParams();

    // Extract the necessary query parameters
    const facilityId = searchParams.get('facilityId');

    return (route: string | number, additionalSearchParams?: URLSearchParams, options: NavigateOptions = {}) => {
        if (typeof route === 'number') {
            navigate(route);
        } else {
            // Parse the route to extract the pathname and existing query parameters
            const url = new URL(route, window.location.origin);
            const routeSearchParams = new URLSearchParams(url.search);

            // Add or update parameters from additionalSearchParams
            if (additionalSearchParams) {
                additionalSearchParams.forEach((value, key) => {
                    routeSearchParams.set(key, value);
                });
            }

            // TODO: shouldn't even need this param is we have a kioskFacilityId.... Check on this
            // Ensure facilityId is preserved if not already present in the route
            if (facilityId && !routeSearchParams.has('facilityId')) {
                routeSearchParams.set('facilityId', kioskFacilityId || facilityId);
            }

            // Ensure facilityId is preserved if not already present in the route
            if (kioskFacilityId && !routeSearchParams.has('kioskFacId')) {
                routeSearchParams.set('kioskFacId', kioskFacilityId);
            }

            // if (paymentFacilityId && !routeSearchParams.has('paymentFacId')) {
            //     routeSearchParams.set('paymentFacId', paymentFacilityId);
            // }

            // Use navigate with an object argument
            navigate({
                pathname: url.pathname,
                search: routeSearchParams.toString(),
            }, options);
        }
    };
};
