import { Body2 } from "../../../brand/typographies";
import { kioskFacilityId } from "../../../globalContext";

export type PaymentResult = {
    state: PaymentState,
    paymentErrorCode: number,
    error?: string
}

export enum PaymentState {
    Failure = 0,
    Success = 1,
    SuccessButFailedToSavePaymentMethod = 2,
    SuccessButFailedToUpdateAutopay = 3,
}

export const handlePaymentResponse = (
    result: PaymentResult,
    onError: any,
    onSuccess: any,
    refetchLedgers: () => void,
    authPhoneNumber: string,
    localPhoneNumber: string,
    trackEvent: (eventName: string) => void,
    autopayEnabled: boolean
) => {
    if (result.state == 1) {
        onSuccess("Thank you. Your payment has been successful.", "Payment Complete");
        if (autopayEnabled) {
            setTimeout(() => trackEvent("Enabled Autopay"), 0);
        }
    } else if (result.state == PaymentState.SuccessButFailedToSavePaymentMethod) {
        setTimeout(() => trackEvent("Failed to Save Card"), 0);
        onSuccess("But... " + result.error, "Payment Successful");
    } else if (result.state == PaymentState.SuccessButFailedToUpdateAutopay) {
        setTimeout(() => trackEvent("Failed to Update AutoPay"), 0);
        onSuccess("But... " + result.error, "Payment Successful");
    } else {
        onError(<>{result.error} <br /> <Body2>code:{result.paymentErrorCode}</Body2></>)
    }

    if (authPhoneNumber != localPhoneNumber && !kioskFacilityId) {
        trackEvent("Payment Complete - NonAccount Holder");
    } else {
        trackEvent("Payment Complete");
    }

    refetchLedgers(); // this updates the global account context with the paid ledger.
};
