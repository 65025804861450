import { useContext, useState } from "react";
import { AuthContext } from "../../providers/auth-provider/AuthProvider";
import { VerifyOtp } from "./VerifyOtp";
import { EnterPhoneNumber } from "./EnterPhoneNumber";

export const SmsVerificationWrapper = ({ children }: any) => {
    const { phoneNumber } = useContext(AuthContext);
    const [verifyingOtp, setVerifyingOtp] = useState(false);

    if (phoneNumber) {
        return children;
    }

    if (verifyingOtp) {
        return <VerifyOtp />
    }

    return <EnterPhoneNumber setVerifyingOtp={setVerifyingOtp} />
};
