import { createContext, useContext } from "react";
import { useLoadScript } from '@react-google-maps/api';

export const MapsApiContext = createContext<{
    isLoaded: boolean,
    loadError?: {
        message: string
    }
}>({
    isLoaded: false
});

export const GoogleScriptProvider = ({ children }: any) => {
    const context = useLoadScript({
        googleMapsApiKey: "AIzaSyC2mYcHLmvjBlmEwuWNs6JRfqV725kQW4M",
        libraries: ["places"],
        version: "3.51"
    });

    return (
        <MapsApiContext.Provider value={context}>
            {children}
        </MapsApiContext.Provider>
    )
}
