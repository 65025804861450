import { BottomNavigation, BottomNavigationAction, Box, Divider, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, styled, SwipeableDrawer } from '@mui/material';
import { useLocation, useNavigate } from "react-router-dom";
import { isIOS } from "react-device-detect";
import { HomeIcon } from './components/icons/HomeIcon';
import { BackIcon } from './components/icons/BackIcon';
import { ChatIcon } from './components/icons/ChatIcon';
import { FacilityIcon } from './components/icons/FacilityIcon';
import { AccountIcon } from './components/icons/AccountIcon';
import { companyConfig, logoBase64String } from './globalContext';
import { useSelectFacility } from './hooks/useSelectFacility';
import { useStoreSelfNavigation } from './hooks/useStorSelfNavigation';
import useAnalytics from './hooks/useAnalytics';
import { startChat } from './startChat';
import { BannerType } from './types/BannerType';
import { useContext, useState } from 'react';
import { PopoverContext } from './providers/PopoverContextProvider';
import React from 'react';
import LogoutIcon from './components/icons/LogoutIcon';
import agents from './api/agents';
import { AuthContext } from './providers/auth-provider/AuthProvider';
import MenuIcon from './components/icons/MenuIcon';
import { CloseIcon } from './components/icons/CloseIcon';
import { isMobile } from "react-device-detect";


type LayoutProps = {
    children: React.ReactNode,
    noBackButton?: boolean,
}

const clipPaths = {
    [BannerType.Chevron]: {
        innerLarge: 'polygon(0% 0%, 100% 0%, 100% 65%, 50% 100%, 0% 65%)',
        innerSmall: 'polygon(0% 0%, 100% 0%, 100% 90%, 50% 100%, 0% 90%)',
        outerLarge: 'polygon(0% 0%, 100% 0%, 100% 67%, 50% 100%, 0% 67%)',
        outerSmall: 'polygon(0% 0%, 100% 0%, 100% 91%, 50% 100%, 0% 91%)',
    },
    [BannerType.InverseChevron]: {
        innerLarge: 'polygon(0% 0%, 100% 0%, 100% 95%, 50% 75%, 0% 95%)',
        innerSmall: 'polygon(0% 0%, 100% 0%, 100% 95%, 50% 90%, 0% 95%)',
        outerLarge: 'polygon(0% 0%, 100% 0%, 100% 95%, 50% 77%, 0% 95%)',
        outerSmall: 'polygon(0% 0%, 100% 0%, 100% 95%, 50% 91%, 0% 95%)',
    },
    [BannerType.Block]: {
        innerLarge: 'polygon(0% 0%, 100% 0%, 100% 88%, 0% 88%)',
        innerSmall: 'polygon(0% 0%, 100% 0%, 100% 88%, 0% 88%)',
        outerLarge: 'polygon(0% 0%, 100% 0%, 100% 89%, 0% 89%)',
        outerSmall: 'polygon(0% 0%, 100% 0%, 100% 89%, 0% 89%)',
    }
}

const config = {
    clipPath: clipPaths[companyConfig.bannerDesign] || clipPaths[BannerType.InverseChevron]
}

export const PageLayout = ({
    children
}: LayoutProps) => {
    const { pathname } = useLocation();
    const navigate = useStoreSelfNavigation();
    const { trackClickEvent } = useAnalytics("Layout")
    const [drawerOpen, setDrawerOpen] = useState(false);

    const isLandingPage = pathname === "/";

    const { showSelectFacility } = useSelectFacility(!isLandingPage)
    const { phoneNumber, logout } = useContext(AuthContext)

    return (
        <PageLayoutContainer>
            <Banner />

            {children}

            <Paper sx={{ opacity: 0, height: isIOS ? "150px" : "100px", width: "100%" }} elevation={3} />
            <Paper sx={{
                transition: 'bottom 0.5s ease-out',
                position: 'fixed',
                bottom: 0,
                left: 0,
                right: 0,
                path: {
                    fill: "#191919"
                }
            }} elevation={3}>
                <BottomNavigation
                    showLabels
                >
                    {!isLandingPage &&
                        <BottomNavigationAction
                            onClick={() => {
                                trackClickEvent("Navigate Back")
                                navigate(-1)
                            }}
                            label={"Back"}
                            icon={<BackIcon />} />
                    }

                    <BottomNavigationAction
                        onClick={() => {
                            trackClickEvent("Navigate FacilitySelect")
                            showSelectFacility()
                        }}
                        label={"Change Facility"}
                        icon={<FacilityIcon />} />

                    {!isLandingPage &&
                        <BottomNavigationAction
                            onClick={() => {
                                trackClickEvent("Navigate Home")
                                navigate("/")
                            }}
                            label="Home" icon={<HomeIcon />} />
                    }

                    <BottomNavigationAction
                        onClick={() => {
                            trackClickEvent("Navigate Account")
                            navigate("/account")
                        }}
                        label={phoneNumber ? "Account" : "Login"} icon={<AccountIcon />} />

                    {!isMobile && companyConfig.swivlId
                        && <BottomNavigationAction
                            onClick={() => {
                                trackClickEvent("Open Chat");
                                startChat();
                                setDrawerOpen(false)
                            }} label="Chat" icon={<ChatIcon />} />
                    }

                    <DrawerMenu
                        renderHomeButton={!isLandingPage}
                        trackClickEvent={trackClickEvent}
                        showSelectFacility={showSelectFacility}
                    />
                </BottomNavigation>
            </Paper>
        </PageLayoutContainer >
    );
}

const DrawerMenu = ({
    renderHomeButton,
    trackClickEvent,
    showSelectFacility
}: {
    renderHomeButton: boolean;
    trackClickEvent: (arg0: string) => void;
    showSelectFacility: () => void
}) => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const navigate = useStoreSelfNavigation();
    const { phoneNumber, logout } = useContext(AuthContext)

    return (
        <React.Fragment>
            <BottomNavigationAction
                onClick={() => setDrawerOpen(true)}
                label="Menu" icon={<MenuIcon />} />

            <SwipeableDrawer
                anchor={"right"}
                open={drawerOpen}
                onClose={() => setDrawerOpen(false)}
                onOpen={() => setDrawerOpen(true)}
            >
                <Box sx={{
                    width: "100vw",
                    maxWidth: "280px",
                    bgcolor: "#191919",
                    height: "100%",
                    color: "#FCFCFC",
                    path: {
                        color: "#FCFCFC!important",
                        fill: "#FCFCFC!important",
                    },
                    span: {
                        color: "#FCFCFC"
                    },
                    padding: "24px 16px",
                    boxSizing: "border-box",
                    display: "flex",
                    gap: "16px",
                    flexDirection: "column"
                }}>
                    <Box sx={{
                        width: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        pr: "16px",
                        boxSizing: "border-box",
                        path: {
                            color: "#FCFCFC!important",
                            fill: "#FCFCFC!important",
                        },
                    }}>
                        <Box onClick={() => setDrawerOpen(false)}>
                            <CloseIcon />
                        </Box>
                    </Box>
                    <Box sx={{ flexGrow: 1 }}></Box> {/* This will take up the remaining space */}

                    {renderHomeButton &&
                        <DrawerMenuItem
                            onClick={() => {
                                trackClickEvent("Navigate Home")
                                navigate("/")
                                setDrawerOpen(false)
                            }}
                            text={"Home"}
                            Icon={HomeIcon}
                        />
                    }

                    <DrawerMenuItem
                        onClick={() => {
                            trackClickEvent("Navigate FacilitySelect")
                            showSelectFacility()
                            setDrawerOpen(false)
                        }}
                        text={"Change Facility"}
                        Icon={FacilityIcon}
                    />

                    {
                        companyConfig.swivlId
                        &&
                        <DrawerMenuItem
                            onClick={() => {
                                trackClickEvent("Open Chat");
                                startChat();
                                setDrawerOpen(false)
                            }}
                            text={"Chat"}
                            Icon={ChatIcon}
                        />
                    }

                    <Divider light sx={{ borderColor: "#FCFCFC" }} />

                    {phoneNumber &&
                        <DrawerMenuItem
                            onClick={logout}
                            text={"Logout"}
                            Icon={LogoutIcon}
                        />
                    }

                    <DrawerMenuItem
                        onClick={() => {
                            trackClickEvent("Navigate Account")
                            navigate("/account")
                            setDrawerOpen(false)
                        }}
                        text={phoneNumber ? "Account" : "Login"}
                        Icon={AccountIcon}
                    />
                </Box>
            </SwipeableDrawer>
        </React.Fragment>

    )
}

const DrawerMenuItem = ({
    onClick,
    text,
    Icon
}: {
    onClick: () => void;
    text: string;
    Icon: () => JSX.Element
}) => (
    <Box sx={{ display: "flex", justifyContent: "flex-end", alignItems: "center" }}>
        <Box
            onClick={onClick}
            sx={{ display: "flex", gap: "16px", alignItems: "center" }}>
            <span>{text}</span>
            <Icon />
        </Box>
    </Box>
)

// TODO: Style the image size better
const LogoImage = styled("img")({
    cursor: "pointer",
    maxWidth: "100%",
    maxHeight: "100%",
    display: "block"
})

const LogoContainer = styled("div")(({ popoverVisible }: { popoverVisible?: boolean }) => ({
    padding: (popoverVisible && companyConfig.bannerDesign === 0) ? "32px" : "24px",
    boxSizing: "border-box",
    height: popoverVisible
        ? "100%"
        : "75%",
    width: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    transition: 'height 0.7s ease-in'
}))

const BannerBox = styled("div")(({ popoverVisible }: { popoverVisible?: boolean }) => ({
    width: "100vw",
    height: popoverVisible ? "15vh" : "33vh",
    backgroundColor: companyConfig.bannerBackgroundColor,
    clipPath: popoverVisible
        ? config.clipPath.innerSmall
        : config.clipPath.outerLarge,
    transition: 'clip-path 0.9s ease-in-out, height 0.9s ease-out',
    zIndex: 50,
}))

const BannerBoxContainer = styled("div")(({ popoverVisible, ismobile }: { popoverVisible?: boolean, ismobile?: boolean }) => ({
    ...(ismobile
        ? {}
        : { display: "none" }),
    paddingBottom: popoverVisible ? "10px" : "14px",
    width: "100vw",
    height: popoverVisible ? "15vh" : "33vh",
    backgroundColor: companyConfig.bannerBorderColor,
    clipPath: popoverVisible
        ? config.clipPath.outerSmall
        : config.clipPath.outerLarge,
    transition: 'clip-path 0.9s ease-in-out, height 0.9s ease-out, padding 0.9s ease-out, margin 0.9s ease-out',
    marginBottom: popoverVisible ? "16px" : "24px",
    zIndex: 50,
    position: "relative",
}))

const PageLayoutContainer = styled("div")(() => ({
    backgroundColor: "#FCFCFC",
    position: "relative",
    height: "100vh",
    width: "100vw",
    overflowY: "scroll",
    boxSizing: "border-box",
}));


export const Banner = () => {
    const { popoverVisible } = useContext(PopoverContext)
    const { pathname } = useLocation();
    const navigate = useStoreSelfNavigation();

    return (
        <BannerBoxContainer popoverVisible={popoverVisible || pathname != "/"} ismobile={true}>
            <BannerBox popoverVisible={popoverVisible || pathname != "/"} >
                <LogoContainer popoverVisible={popoverVisible || pathname != "/"} >
                    <LogoImage
                        onClick={() => navigate("/")} src={`data:image/jpeg;base64,${logoBase64String}`}
                    />
                </LogoContainer>
            </BannerBox>
        </BannerBoxContainer>
    )
}
