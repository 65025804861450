
export type CardType =
    "visa"
    | "mastercard"
    | "american_express"
    | "american express"
    | "discover"
    | "diners"
    | "jcb";

export interface IPaymentMethod {
    id: string;
    kind: "credit_card" | "ach" | "cash";
    firstName: string;
    lastName: string;
    fullName: string;
    nameOnCard?: string;
    cardNumber: string;
    cardType: CardType;
    expirationDate: string;
    expirationMonth: string;
    expirationYear: string;
    accountNumber?: string;
    routingNumber?: string;
    bankName?: string;
    paymentMethodId: string;
}

// Constants
export const KIND_CREDIT_CARD = "credit_card";
export const KIND_ACH = "ach";
export const KIND_CASH = "cash";
export const CARD_TYPE_VISA = "visa";
export const CARD_TYPE_MASTERCARD = "mastercard";
export const CARD_TYPE_AMERICAN_EXPRESS = "american_express";
export const CARD_TYPE_AMERICAN_EXPRESS2 = "american express";
export const CARD_TYPE_DISCOVER = "discover";
export const CARD_TYPE_DINERS = "diners";
export const CARD_TYPE_JCB = "jcb";
