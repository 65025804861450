import React, { createContext, useState } from "react";
import { Box, Dialog, Fade } from "@mui/material";
import { TransitionProps } from "@mui/material/transitions/transition";
// TODO: use svg icons instead.
import CancelIcon from '@mui/icons-material/CloseRounded';

import { Colors } from "../brand/colors";

interface IPopoverContext {
    onShowPopover: (component: JSX.Element, options?: PopoverOptions) => void,
    onClosePopover: () => void,
    popoverVisible?: boolean,
}

export const PopoverContext = createContext<IPopoverContext>({
    onShowPopover: () => { },
    onClosePopover: () => { },
});

type PopoverOptions = {
    showHeader?: boolean,
    showCancelIcon?: boolean,
    light?: boolean,
}

export const PopoverContextProvider = ({ children }: { children: React.ReactNode }) => {
    const [component, setComponent] = useState<null | (JSX.Element)>(null)
    const [options, setOptions] = useState<PopoverOptions>({})
    const [callback, setCallback] = useState<null | (() => void)>(null)

    const onShowPopover = (component: JSX.Element, options: PopoverOptions = {}) => {
        setComponent(component)
        setOptions(options)
    }

    const onClosePopover = () => {
        callback && callback()
        setComponent(null)
        setTimeout(() => {
            setCallback(null)
            setOptions({})
        }, 300)
    }

    return (
        <PopoverContext.Provider
            value={{
                onShowPopover,
                onClosePopover,
                popoverVisible: !!component,
            }}
        >
            {children}
            <Dialog
                fullScreen
                open={!!component}
                onClose={onClosePopover}
                TransitionComponent={Transition}
                sx={{
                    ...(options.showHeader ? { zIndex: 1 } : {}),
                    '& .MuiPaper-root': {  // Target the root paper element of the dialog
                        backgroundColor: (options.showHeader || options.light) ? '#FCFCFC' : '#191919',
                    },
                }}
            >
                <Box sx={{ pt: options.showHeader ? "180px" : 0, px: "32px" }}>
                    {component}
                </Box>

                {options.showCancelIcon &&
                    <Box
                        onClick={onClosePopover}
                        sx={{
                            bgcolor: Colors.primary(),
                            width: "48px",
                            height: "48px",
                            borderRadius: "50%",
                            position: "absolute", bottom: "28px", right: "32px", display: "flex", alignItems: "center", justifyContent: "center"
                        }}
                    >
                        <CancelFAB onClick={onClosePopover} />
                    </Box>
                }
            </Dialog>
        </PopoverContext.Provider>
    )
}

export const CancelFAB = ({ onClick }: { onClick: () => void }) =>
    <CancelIcon onClick={onClick} sx={{ color: "#FCFCFC" }} fontSize="medium" />

const Transition = React.forwardRef(function Transition(
    props: TransitionProps & {
        children: React.ReactElement;
    },
    ref: React.Ref<unknown>,
) {
    // Set the duration in milliseconds
    const duration = {
        enter: 150,
        exit: 200,
    };

    return <Fade ref={ref} {...props} timeout={duration} />;
});
