import {
    createContext,
    useEffect,
    useState
} from "react";
import { getTenantAuth as getGlobalAuth, setTenantAuth as setGlobalAuth } from "../../globalContext";
import { identify } from "../../services/analytics-service";
import agents from "../../api/agents";
import { useNavigate } from "react-router-dom";

interface IAuthContext {
    phoneNumber: string,
    setTenantAuth: (tenantAuth: { phoneNumber: string }) => void
    logout: () => void
};

export const AuthContext = createContext<IAuthContext>({
    phoneNumber: "",
    setTenantAuth: (_: { phoneNumber: string }) => { },
    logout: () => { }
});

const AuthProvider = ({ children }: { children: any }) => {
    // tenant auth is hydrated from the server. It's either there or it isn't.
    const [tenantAuth, setTenantAuth] = useState(getGlobalAuth());
    console.log({ tenantAuth })

    const navigate = useNavigate()

    useEffect(() => {
        if (tenantAuth?.phoneNumber) {
            identify(tenantAuth.phoneNumber)
        }
    }, [tenantAuth])

    const logout = async () => {
        await agents.Auth.logout();
        setGlobalAuth("")
        setTenantAuth(getGlobalAuth())
        navigate("/")

        setTimeout(() =>
            window.location.reload(),
            0
        )
    }

    return (
        <AuthContext.Provider
            value={{
                // we can set the tenant auth state if we successfully authorize
                // we don't need to update the global context as that global context with the tenant auth
                // is only used on load to set up this auth provider.
                // if the authorization was successful on the server, then the next time we load the app,
                // it will be there on the global context
                setTenantAuth,
                logout,
                phoneNumber: tenantAuth?.phoneNumber || "",
            }}
        >
            {children}
        </AuthContext.Provider>

    )
}

export default AuthProvider;
