import { Subtitle1, PageTitle, Subtitle2 } from "../brand/typographies";
import { useCallback, useEffect, useRef, useState } from "react";
import { Autocomplete, Box, TextField } from "@mui/material";
import { Section } from "../components/layout/Section";
import { address, addressEl } from "../utils/address";
import { IFacility } from "../types/IFacility";
import { StorSelfButton } from "../components/buttons/StorSelfButton";
import { useStoreSelfNavigation } from "../hooks/useStorSelfNavigation";
import useAnalytics from "../hooks/useAnalytics";
import { useLocation } from "react-router-dom";

const test = {}

// this could easily become generic since the facilities and setSelectedFacility are passed in...
export const FacilitySelect = ({ onContinue, facilities, setSelectedFacility, closestFacility }: {
    onContinue?: () => void,
    facilities: IFacility[],
    closestFacility?: IFacility | null,
    setSelectedFacility: (facility: IFacility) => void
}) => {
    const navigate = useStoreSelfNavigation();
    useAnalytics("FacilitySelect", true)

    const { pathname } = useLocation()
    const pathnameRef = useRef(pathname)

    useEffect(() => {
        if (pathname !== pathnameRef.current) {
            onContinue && onContinue() // TODO: we should require onContinue now...
        }
    }, [pathname])

    const [value, setValue] = useState<IFacility | null>(null)

    const handleContinue = useCallback(() => {
        if (value) {
            setSelectedFacility!(value)
        }
        if (onContinue) {
            onContinue()
        } else {
            navigate(-1)
        }
    }, [value])

    return (
        <>
            <PageTitle sx={pathname === "/" ? { mt: 4 } : {}}>Select Facility</PageTitle>

            <Section sx={{ mt: 2, mb: 12 }}>
                <Subtitle1 center>Start typing any part of the address<br />to find facility.</Subtitle1>
                <Autocomplete
                    fullWidth
                    id="facility-select-input"
                    options={facilities}
                    autoHighlight
                    autoFocus
                    value={value}
                    getOptionLabel={(option: IFacility) => address(option)}
                    renderOption={(props, option: IFacility) => (
                        <Box key={address(option)} component="li" {...props} sx={{ lineHeight: 1.1 }}>
                            {addressEl(option)}
                        </Box>
                    )}
                    onChange={(_, value) => {
                        setValue(value)
                    }}
                    renderInput={(params) => (
                        <TextField
                            {...params}
                            label="Search for facility"
                            sx={{
                                ...test
                            }}
                            inputProps={{
                                ...params.inputProps,
                                autoComplete: 'new-password',
                            }}
                            InputProps={{
                                sx: {
                                    ...test,
                                },
                                ...params.InputProps
                            }}
                        />
                    )}
                />

                {
                    closestFacility &&
                    <Subtitle2
                        center
                        sx={{ cursor: "pointer" }}
                        onClick={() => {
                            setValue(closestFacility)
                            // trackClickEvent("Clicked Use Closest Facility")
                            // navigate("/auction-buyer")
                        }}
                        bold
                        brandColor>Use Closest Facility</Subtitle2>
                }
            </Section >

            <StorSelfButton
                analyticsTag="Select Facility"
                onClick={handleContinue}>Continue</StorSelfButton>
        </>
    );
};
