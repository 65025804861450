import { SvgIconContainer } from "./SvgIconContainer";


export const PhoneIcon = () => (
    <SvgIconContainer>
        <svg width="88" height="88" viewBox="0 0 88 88" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_10_5421)">
                <path d="M65.9772 88C64.3911 87.6553 62.7899 87.3561 61.2152 86.9509C54.7267 85.2843 48.7202 82.4853 43.0817 78.9326C28.0217 69.4372 15.8985 57.0746 7.05754 41.5949C3.80193 35.8984 1.38489 29.8573 0.185856 23.3616C-0.182202 21.3732 -0.0645755 19.6309 1.14964 17.8924C5.13377 12.1884 9.48975 6.81003 14.6008 2.06801C15.2193 1.4923 15.9099 0.977195 16.6195 0.511326C17.6705 -0.174221 18.5205 -0.132558 19.5032 0.67419C20.1027 1.17036 20.6833 1.73471 21.131 2.36344C25.1834 8.07507 28.9133 13.9837 31.9375 20.3164C32.1044 20.6687 32.2031 21.055 32.389 21.3997C33.2883 23.0927 32.814 24.479 31.4556 25.672C29.5622 27.3348 27.7295 29.0733 25.745 30.6262C24.379 31.6942 24.2842 32.7775 24.8381 34.3114C29.8999 48.2989 39.4087 57.8549 53.3759 62.9832C55.7702 63.8619 55.8271 63.8165 57.5801 61.9189C58.8892 60.5024 60.1072 58.9987 61.3745 57.5443C61.7388 57.1239 62.1334 56.73 62.5129 56.3247C63.6436 55.1051 64.9071 54.8438 66.4932 55.5066C70.9782 57.3852 75.0989 59.9229 79.2083 62.4719C81.2079 63.7142 83.2 64.983 85.0782 66.3996C86.1748 67.2253 87.0361 68.3578 88.0037 69.3539V70.5318C87.0361 71.7931 86.19 73.1793 85.0744 74.2966C79.8913 79.5121 74.3325 84.2768 67.9465 88.0038C67.29 88.0038 66.6374 88.0038 65.981 88.0038L65.9772 88Z" fill="white" />
                <path d="M88.0001 40.8828H81.7279C81.5003 31.414 78.2523 23.2215 71.5703 16.5289C64.8807 9.8287 56.6924 6.54489 47.176 6.31385V0C48.7317 0.0984764 50.2912 0.117414 51.8318 0.306792C60.9535 1.43548 68.8839 5.13971 75.4937 11.5217C81.8949 17.703 85.8752 25.2062 87.4006 33.9819C87.6283 35.2962 87.799 36.6181 87.9963 37.9361V40.8791L88.0001 40.8828Z" fill="white" />
                <path d="M72.2305 40.8374H66C65.8217 35.7166 64.0763 31.2284 60.4147 27.5772C56.772 23.9487 52.3174 22.1875 47.157 22.0208V15.8547C59.5419 15.3585 72.3101 26.1 72.2305 40.8374Z" fill="white" />
            </g>
            <defs>
                <clipPath id="clip0_10_5421">
                    <rect width="88" height="88" fill="white" />
                </clipPath>
            </defs>
        </svg>

    </SvgIconContainer>
);
