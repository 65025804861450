import { useCallback, useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";

import { PrimaryButtonProps } from "./StorSelfButton";
import { useStoreSelfNavigation } from "../../hooks/useStorSelfNavigation";
import useAnalytics from "../../hooks/useAnalytics";
import { getContrastRatio } from "@mui/material";
import { Colors } from "../../brand/colors";

export type IconButtonProps = PrimaryButtonProps & {
    text?: string;
    to?: string;
    Icon: () => JSX.Element;
    pending?: boolean
};

export const StorSelfIconButton = ({ to, text, children, Icon, pending, analyticsTag, variant = "contained", sx = {}, ...props }: IconButtonProps) => {
    const navigate = useStoreSelfNavigation();

    const { trackClickEvent } = useAnalytics("StorSelfIconButton")

    const onClick = useCallback(() => {
        if (to) {
            analyticsTag ? trackClickEvent(analyticsTag) : trackClickEvent("Navigate - " + to)
            return navigate(to);
        } else {
            analyticsTag && trackClickEvent(analyticsTag)
        }
    }, []);

    return <LoadingButton
        loading={pending}
        onClick={onClick}
        variant={variant}
        {...props}
        sx={{
            p: 1,
            pt: 2,
            height: "110px",
            display: "flex",
            flexDirection: "column",
            textAlign: "center",
            // if variant is contained, then we want to set the text color to white if the contrast ratio is high enough
            // black just looks bad...
            ...(variant === "contained" && {
                color: getContrastRatio(Colors.primary(), "#FFFFFF") > 2.7 ? "#FFFFFF" : "#000000",
                borderColor: Colors.primary(),
                "&:focus": {
                    color: getContrastRatio(Colors.primary(), "#FFFFFF") > 2.7 ? "#FFFFFF" : "#000000",
                }
            }),
            ...(props.fullWidth && { gridColumn: "1/-1" }),
            ...sx
        }}
    >
        <Icon />
        {text || children}
    </LoadingButton>;
};
