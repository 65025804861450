import { ChatIcon } from "../../icons";
import { StorSelfIconButton } from "../StorSelfIconButton";
import { startChat } from '../../../startChat';

export const SwivlChatButton = () => (
    <StorSelfIconButton
        sx={{
            svg: {
                height: "38px!important",
                width: "38px!important",
                path: {
                    fill: "#FCFCFC"
                }
            }
        }}
        Icon={ChatIcon}
        text="Chat"
        onClick={startChat} />
);
