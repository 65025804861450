import { SvgIconContainer } from "./SvgIconContainer";

export const CloseIcon = () => (
    <SvgIconContainer>
        <svg width="25" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_420_8203)">
                <path d="M14.1101 12.2207C14.1701 12.2707 14.2301 12.3007 14.2801 12.3507C15.5301 13.5907 16.7701 14.8407 18.0201 16.0907C18.6001 16.6707 18.6001 17.3007 18.0201 17.8807C17.8801 18.0207 17.7501 18.1607 17.6101 18.2907C17.1201 18.7707 16.4501 18.7707 15.9601 18.2907C15.2601 17.5907 14.5601 16.8907 13.8501 16.1907C13.2701 15.6107 12.7001 15.0407 12.1201 14.4607C12.0801 14.4207 12.0401 14.3907 12.0001 14.3507C11.9501 14.4007 11.9001 14.4407 11.8601 14.4907C10.6101 15.7407 9.36006 16.9907 8.11006 18.2407C7.54006 18.8107 6.90006 18.8107 6.34006 18.2407C6.20006 18.1007 6.06006 17.9607 5.92006 17.8207C5.45006 17.3307 5.45006 16.6707 5.92006 16.1807C6.58006 15.5107 7.25006 14.8507 7.91006 14.1907C8.52006 13.5807 9.12006 12.9807 9.73006 12.3707C9.78006 12.3207 9.83006 12.2907 9.89006 12.2307C9.82006 12.1607 9.78006 12.1107 9.73006 12.0607C8.48006 10.8107 7.23006 9.56068 5.98006 8.31068C5.42006 7.75068 5.42006 7.11068 5.98006 6.55068C6.11006 6.42068 6.25006 6.28068 6.38006 6.15068C6.90006 5.64068 7.56006 5.63068 8.08006 6.15068C9.34006 7.41068 10.6001 8.67068 11.8701 9.94068C11.9101 9.98068 11.9601 10.0307 12.0201 10.0807C12.0701 10.0307 12.1201 9.99068 12.1601 9.94068C13.4101 8.69068 14.6601 7.44068 15.9101 6.19068C16.4801 5.62068 17.1201 5.62068 17.6801 6.19068C17.8201 6.33068 17.9601 6.46068 18.0901 6.60068C18.5801 7.10068 18.5801 7.77068 18.0901 8.26068C17.4101 8.94068 16.7301 9.62068 16.0501 10.3007C15.4501 10.9007 14.8501 11.5007 14.2601 12.0907C14.2301 12.1207 14.2001 12.1607 14.1301 12.2307L14.1101 12.2207Z" fill="#FCFCFC" />
            </g>
            <defs>
                <clipPath id="clip0_420_8203">
                    <rect width="24" height="24" fill="white" transform="translate(0 0.190918)" />
                </clipPath>
            </defs>
        </svg>
    </SvgIconContainer>
);
