import { useStoreSelfNavigation } from '../../../hooks/useStorSelfNavigation';
import { useAppContext } from '../../../hooks/useAppContext';
import { Box, Grid } from '@mui/material';
import { PageTitle, Subtitle1 } from '../../../brand/typographies';
import { FacilityAccountCard } from '../[components]/FacilityAccountCard';
import { CardFacilityAddress } from '../[components]/CardFacilityAddress';
import { ColumnedRow } from '../../../components/ColumnedRow';
import { useUnlockCodes } from '../../access/unit/useUnlockCodes';
import { StorSelfButton } from '../../../components/buttons/StorSelfButton';

function NewMoveInAccountPage() {
    const navigate = useStoreSelfNavigation();
    const { facilityLedgers, loadingAppContext, selectedFacility } = useAppContext()
    const [locks, fetchingLocks] = useUnlockCodes(selectedFacility)

    if (loadingAppContext) {
        return <h1>Loading...</h1>
    }

    const renderData = () => {
        if (fetchingLocks) {
            return null
        }

        if (facilityLedgers.length === 1 && locks.length === 1) {
            const ledger = facilityLedgers[0];
            return (
                <ColumnedRow
                    sx={{ maxWidth: "200px", margin: "auto" }}
                    largeText
                    loading={loadingAppContext}     
                    data={[
                        { label: "Unit", value: ledger?.facilityUnit?.unitName },
                        { label: "Unlock Code", value: locks[0].unlockCode || "" }
                    ]}
                />
            )
        } else if (facilityLedgers.length === 1 && locks.length > 1) {
            const ledger = facilityLedgers[0];
            return (
                <>
                    <ColumnedRow
                        largeText
                        loading={loadingAppContext}
                        data={[
                            { label: "Unit", value: ledger?.facilityUnit?.unitName },
                        ]}
                    />
                    {
                        locks.map((lock) =>
                        (
                            <ColumnedRow
                                sx={{ maxWidth: "200px", margin: "auto" }}
                                largeText
                                loading={loadingAppContext}
                                data={[
                                    { label: "Serial Code", value: lock.serialCode },
                                    { label: "Unlock Code", value: lock.unlockCode || "", success: true }
                                ]}
                            />
                        )
                        )}
                </>
            )
        }

        return null;
    }

    return (
        <>
            <PageTitle>Welcome!</PageTitle>
            <Subtitle1 center sx={{ mb: 4 }}>We're here to help.</Subtitle1>

            <Grid spacing={2}>
                <FacilityAccountCard>
                    <CardFacilityAddress facility={selectedFacility} />
                    <Box width="100%" height="24px" />
                    {renderData()}
                </FacilityAccountCard>

                <StorSelfButton
                    onClick={() => navigate("/access/access-points?new-move-in=true")}
                    sx={{ mt: 4 }}>Open Gate/Door</StorSelfButton>
            </Grid>
        </>
    )
}

export default NewMoveInAccountPage
