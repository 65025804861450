import { useContext, useMemo } from "react";
import { PageTitle, Subtitle1 } from "../../../../brand/typographies";
import { FacilityAddress } from "../../../../components/FacilityAddress";
import { Box } from "@mui/material";
import useAnalytics from "../../../../hooks/useAnalytics";
import { StringParam, useQueryParam } from "use-query-params";
import { StorSelfButton } from "../../../../components/buttons/StorSelfButton";
import { useStoreSelfNavigation } from "../../../../hooks/useStorSelfNavigation";
import { useSuccessError } from "../../../../hooks/useSuccessError";
import { FacilitiesContext } from "../../../../providers/facility-provider/FacilitiesContext";
import agents from "../../../../api/agents";
import { useSearchParams } from "react-router-dom";
import { auctionBuyerRoutes, mainRoutes } from "../../../../router/routes";


// TODO: ponder how similar this page is to the over view page...
export function ConfirmAuctionBuyerMoveoutPage() {
    const { selectedFacility, atFacility } = useContext(FacilitiesContext)
    const [firstName] = useQueryParam("firstName", StringParam)
    const [lastName] = useQueryParam("lastName", StringParam)
    const [unitName] = useQueryParam("unitName", StringParam)
    const [receiptId] = useQueryParam("receiptId", StringParam)

    const [searchParams] = useSearchParams();

    const navigate = useStoreSelfNavigation();
    const { trackEvent, trackClickEvent } = useAnalytics()

    const { onSuccess, onError } = useSuccessError(() => {
        localStorage.removeItem("imageTimestamp")
        localStorage.removeItem("image")
        navigate("/")
    })

    if (!firstName || !lastName || !unitName) {
        navigate("/", undefined, { replace: true })
    }

    // const ledger = ledgers.find(l => l.id == ledgerId);

    // const loaded = !!(ledger && selectedFacility)

    // TODO: ponder on the similarities for reading an image on cameraPage from local storage
    const image = useMemo(() => {
        const imageTimestamp = localStorage.getItem("imageTimestamp")
        if (imageTimestamp && (Date.now() - Number(imageTimestamp)) <= 10 * 60000) {
            const img = localStorage.getItem("image")
            if (img) {
                return img as string
            }
        } else {
            navigate("/")
        }
        return ""
    }, [])

    const handleSubmit = async () => {
        const formData = new FormData();

        const blob = base64ToBlob(image, 'image/jpeg')
        const imageFile = new File([blob], 'image.jpeg', { type: 'image/jpeg' });

        formData.append('image', imageFile);
        formData.append('FirstName', firstName!);
        formData.append('LastName', lastName!);
        formData.append('UnitName', unitName!);
        formData.append('ReceiptId', receiptId!);
        formData.append('FacilityId', selectedFacility!.id);
        formData.append('AtFacility', atFacility ? "true" : "false");
        formData.append('LedgerId', "auction-buyer");

        const result = await agents.Facility.initiateAuctionMoveOut(formData);

        if (result?.data) {
            trackEvent("Auction Moveout Success")
            onSuccess("Your auction buyer unit clean out submission has been received and will be reviewed by a member of our team.If an auction deposit was collected with your winning bid payment, it will be refunded to you in 3 - 5 business days.", "Success")
        } else {
            trackEvent("Moveout Failed")
            onError("We’re sorry, but something went wrong on our end. We will resolve this issue as soon as we can.")
        }
    }

    return (
        <>
            <PageTitle>Finalize Auction</PageTitle>
            <FacilityAddress />

            <Box display="flex" flexDirection="column" sx={{
                mb: 3,
                gap: 3,
                "img": {
                    maxWidth: "100%"
                }
            }}>
                {image && <img src={image} height="200px" style={{ margin: "auto" }} />}

                <Subtitle1
                    onClick={() => {
                        trackClickEvent("Retake Photo")
                        localStorage.removeItem("image")
                        navigate(mainRoutes.AuctionBuyer + auctionBuyerRoutes.MoveoutTakePhoto + "?" + searchParams.toString())
                    }}
                    brandColor
                    underlined
                    center>Retake Photo</Subtitle1>
            </Box>

            <StorSelfButton
                analyticsTag="Finalize Auction Buyer Move Out"
                onClick={handleSubmit}>Submit</StorSelfButton>
        </>
    );
}


function base64ToBlob(base64: string, contentType: 'image/png' | 'image/jpeg') {
    // Decode the base64 string to a byte array
    const byteCharacters = atob(base64.split(',')[1]);
    const byteNumbers = new Array(byteCharacters.length);

    for (let i = 0; i < byteCharacters.length; i++) {
        byteNumbers[i] = byteCharacters.charCodeAt(i);
    }

    const byteArray = new Uint8Array(byteNumbers);

    // Create a blob from the byte array
    return new Blob([byteArray], { type: contentType });
}
