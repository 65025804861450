import { Grid, SxProps, Theme } from '@mui/material';
import { Caption, H2, H4, H5, Subtitle1 } from '../brand/typographies';

type ColumnedRowProps = {
    sx?: SxProps<Theme>,
    data: { label: string; value: string | number;[key: string]: any }[];
    loading: boolean;
    largeText?: boolean;
}

export const ColumnedRow = ({ data, loading, sx = {}, largeText }: ColumnedRowProps) => {
    return (
        <Grid sx={sx} container alignItems="center" justifyContent="center" spacing={0} mt={1}>
            {data.map(({ label, value, ...props }) => <Grid key={label} item xs>
                <Caption loading={loading} center>
                    {label}
                </Caption>
                {largeText
                    ?
                    <H4 loading={loading} bold center {...props}>{value}</H4>
                    :
                    <Subtitle1 loading={loading} bold center {...props}>
                        {value}
                    </Subtitle1>
                }
            </Grid>
            )}
        </Grid>
    );
};

export const CenteredRow = ({ children, sx = {} }: { children: React.ReactNode[], sx?: SxProps<Theme> }) => {
    return (
        <Grid sx={sx} container alignItems="center" justifyContent="center" spacing={0} mt={1}>
            {children.map((child, key) =>
                <Grid key={key} item xs>
                    {child}
                </Grid>
            )}
        </Grid>
    );
};
