import { isMobile } from "react-device-detect";
import { useContext } from "react";
import { FacilitiesContext } from "../../providers/facility-provider/FacilitiesContext";
import { FacilityAddress } from "../../components/FacilityAddress";
import { EmailIcon, PhoneIcon } from "../../components/icons";
import { PageTitle } from "../../brand/typographies";
import { StorSelfIconButton } from "../../components/buttons/StorSelfIconButton";
import { Section } from "../../components/layout/Section";
import { companyConfig } from "../../globalContext";

export const ContactUsPage = () => {
    const { selectedFacility } = useContext(FacilitiesContext);

    return (
        <>
            <PageTitle>Contact Us</PageTitle>
            <FacilityAddress />
            <Section sx={{ my: 4, px: 4, boxSizing: "border-box", }}>
                <StorSelfIconButton
                    fullWidth={!isMobile}
                    Icon={EmailIcon}
                    text="Email us"
                    href={`mailto: ${selectedFacility?.email || companyConfig.contactUsEmail}`} />
                {isMobile &&
                    <StorSelfIconButton
                        Icon={PhoneIcon}
                        text="Call us"
                        href={`tel:${selectedFacility?.phone || companyConfig.supportPhoneNumberFallback}`} />}
            </Section>
        </>
    );
};
