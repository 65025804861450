import { useContext, useState } from "react";

import { analyticsService } from "../../../services/analytics-service";
import { companyConfig } from "../../../globalContext";
import { FacilitiesContext } from "../../../providers/facility-provider/FacilitiesContext";
import { RentSpaceIcon } from "../../icons";
import { StorSelfIconButton } from "../StorSelfIconButton";

/**
 * Renders a button component for renting a space.
 *
 * @returns The RentSpaceButton component.
 */
export const RentSpaceButton = () => {
    const [pending, setPending] = useState(false);
    const { selectedFacility } = useContext(FacilitiesContext)

    return (
        <StorSelfIconButton
            Icon={RentSpaceIcon}
            fullWidth
            pending={pending}
            onClick={() => {
                setPending(true)
                analyticsService.trackEvent("Go To Rental Page", null);
                if (selectedFacility?.websiteUrl) {
                    if (selectedFacility?.websiteUrl.includes("http")) {
                        window.location.href = selectedFacility?.websiteUrl;
                    } else {
                        window.location.href = "https://" + selectedFacility?.websiteUrl;
                    }
                } else {
                    window.location.href = companyConfig.rentSpaceFallback;
                }
            }}
            text="Rent a space" />
    )
}
